<template>
  <div class="exReport_list_container">
    <van-collapse v-model="activeNames2" accordion>
      <van-collapse-item title="总检结果" name="1">
        <div class="content" style="white-space: pre-line;">{{ reportReview }}</div>
      </van-collapse-item>
      <van-collapse-item title="总检建议" name="2">
        <div class="content" style="border-bottom: 0;white-space: pre-line;">{{ suggestion }}</div>
      </van-collapse-item>

    </van-collapse>
    <van-collapse v-model="activeNames" accordion>
      <div v-for="(item, index) in exReportList.groupList" :key="index">
        <div @click="peReportResultDetail(item.groupCode, item.groupType)">
          <van-collapse-item :title=item.groupName>
            <div v-if="item.groupType === '1'">
              <div class="organ_list" v-for="(item1, index1) in exReportDetail.itemList" :key="index1">
                <div style="white-space: pre-line;">{{ item1.itemName }}：{{ item1.result }}</div>
              </div>
            </div>
            <div class="checked_list" v-if="item.groupType === '2'">
              <van-row class="thead">
                <van-col span="5">项目名称</van-col>
                <van-col span="5">项目结果</van-col>
                <van-col span="4">单位</van-col>
                <van-col span="5">参考下限</van-col>
                <van-col span="5">参考上限</van-col>
              </van-row>
              <van-row class="tr" v-for="(items, indexs) in exReportDetail.itemList" :key="indexs">
                <div :class="items.resultTip === '' ? '' : 'anomalous'">
                  <van-col span="5">{{ items.itemName === '' ? '-' : items.itemName }}</van-col>
                  <van-col span="5">{{ items.result === '' ? '-' : items.result }} {{ item.resultTip }}</van-col>
                  <van-col span="4">{{ items.unit === '' ? '-' : items.unit }}</van-col>
                  <van-col span="5">{{ items.lowerValue === '' ? '-' : items.lowerValue }}</van-col>
                  <van-col span="5">{{ items.upperValue === '' ? '-' : items.upperValue }}</van-col>
                </div>
              </van-row>
            </div>
          </van-collapse-item>
        </div>
      </div>
    </van-collapse>
  </div>
</template>
<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "List",
  data () {
    return {
      suggestionShow: false,
      reportNo: "",
      exReportList: [],
      reportReview: "",
      suggestion: "",
      activeNames: ['1'],
      activeNames2: ['1'],
      exReportDetail: {}
    };
  },
  created () {
    this.reportNo = this.$route.query.reportNo;
    this.peReportCombinationInfo();
  },
  methods: {
    //获取体检报告详情
    async peReportResultDetail (groupCode, groupType) {
      console.log("groupCode", groupCode);
      // this.$toast.loading({
      //   duration: 0,
      //   message: "加载中",
      //   forbidClick: true
      // });
      const { data: res } = await jsonPost("/wxapp/pe/peReportResultDetail", {
        reportNo: this.reportNo,
        groupCode: groupCode
      });
      // this.$toast.clear();
      if (res.code === 0) {
        console.log(111, res);
        if (groupType === "0") {
          this.exReportDetail = res.data;
        } else if (groupType === "1") {
          this.exReportDetail = res.data;
          for (let i = 0; i < this.exReportDetail.itemList.length; i++) {
            this.exReportDetail.itemList[
              i
            ].result = this.exReportDetail.itemList[i].result.replace(
              /\\n/g,
              "\n"
            );
          }
        } else if (groupType === "2") {
          this.exReportDetail = res.data;
        }
        this.exReportDetail.suggest = this.exReportDetail.suggest.replace(
          /\\n/g,
          "\n"
        );
      } else {
        this.$toast.fail(res.msg);
      }
    },
    //获取体检报告列表
    async peReportCombinationInfo () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/pe/peReportCombinationInfo",
        {
          reportNo: this.reportNo
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        console.log(res);
        if (res.data.groupList[0].groupName == "采血+材料费6") {
          var newArr = res.data.groupList.slice(0);
          newArr.shift();
          res.data.groupList = newArr
        }
        this.exReportList = res.data;
        this.reportReview = this.exReportList.reportReview.replace(
          /\\n/g,
          "\n"
        );
        this.suggestion = this.exReportList.suggest.replace(/\\n/g, "\n");
      } else {
        this.$toast.fail(res.msg);
      }
    },
    showSuggestion () {
      this.suggestionShow = !this.suggestionShow;
    },
    navDetail (groupCode, groupType, groupName) {
      this.$router.push({
        path: "/exReport/detail",
        query: {
          reportNo: this.reportNo,
          groupCode: groupCode,
          groupType: groupType,
          groupName: groupName
        }
      });
    }
  }
};
</script>
<style  scope>
.van-cell__title {
  font-style: 30px;
}

.tr {
  background-color: aliceblue;
}

.van-collapse-item__content {
  padding: 10px;
  font-size: 20px;
}

.doctor {
  font-size: 38px;
}

.organ_list {
  padding: 20px 30px;
  color: gray;
  font-size: 40px;
  line-height: 80px;
  border-bottom: 1px solid #eee;
}

.total {
  padding: 20px 30px;
  background-color: #f9fceb;
  font-size: 30px;
  line-height: 80px;
  color: #d2b330;
}

.content {
  font-size: 32px;
  margin-top: 10px;
  color: rgba(122, 116, 116, 0.904);
}

.exReport_list_container {
  padding-bottom: 60px;
}

.exReport_list_container .header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
  display: flex;
  padding: 20px 30px;
  border-bottom: 10px solid #eee;
}

.exReport_list_container .header .van-icon {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  font-size: 40px;
}

.exReport_list_container .suggestion {
  margin-left: 10px;
}

.exReport_list_container .suggestion .title {
  font-size: 40px;
  margin-top: 20px;
}

.exReport_list_container .suggestion .content {
  font-size: 32px;
  margin-top: 10px;
  color: rgba(122, 116, 116, 0.904);
}

.exReport_list_container .suggestion_list .van-cell,
.exReport_list_container .checked_list .van-cell {
  padding: 30px;
  font-size: 40px;
  line-height: 50px;
  border-bottom: 10px solid #eee;
}

.exReport_list_container .suggestion_list .van-cell .van-icon,
.exReport_list_container .checked_list .van-cell .van-icon {
  font-size: 40px;
}

.checked_list {
  text-align: center;
  font-size: 34px;
}

.checked_list .thead {
  padding: 20px 10px;
  border-bottom: 1px solid #eee;
}

.checked_list .tr {
  padding: 20px 10px;
  border-bottom: 1px solid #eee;
}

.checked_list .tr .anomalous {
  color: red;
}

.exReport_list_container .suggestion_list .content {
  font-size: 40px;
  padding: 10px 30px;
  color: rgba(122, 116, 116, 0.904);
  line-height: 80px;
  border-bottom: 10px solid #eee;
}
</style>